import { Route, Routes, useLocation } from "react-router-dom";
import Almoni from "./assets/fonts/Almoni/aaa-fonts Almoni/almoni-medium-aaa.otf";
import Arbel from "./assets/fonts/Arbel/ArbelG-Medium.otf";
import Footer from "./domain/Common/Layout/Footer/Footer";
import Header from "./domain/Common/Layout/Header/Header";
import Main from "./domain/Common/Layout/Main/Main";
import DetailsContainer from "./domain/Common/Layout/Header/components/DetailsContainer";
import {
  Handles,
  HandlesToMobile,
  SwiperData1,
  SwiperData1ToMobile,
  SwiperData2,
  SwiperData2ToMobile,
  SwiperDataDefault,
  SwiperDataMobile,
  navList,
} from "./domain/Constants/data";
import MySwiper from "./domain/Common/Layout/Main/components/Swiper";
import HandleViewer from "./domain/Common/Layout/Main/components/HandleViewer";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Almoni';
    src: url(${Almoni}) format('truetype');
  }
  @font-face {
    font-family: 'Arbel';
    src: url(${Arbel}) format('truetype');
  }
  `;
function App() {
  const isMobile = window.matchMedia("(max-width: 600px)").matches;
  const isHDScreen = window.matchMedia("(min-width: 1441px)").matches;
  const isHomePage = useLocation().pathname === "/";
  const isHandlesPage = useLocation().pathname === "/pirzul";
  const currentPage = useLocation().pathname;
  const getImages = () => {
    if (isMobile) {
      if (currentPage === "/sderot") return SwiperData2ToMobile;
      if (currentPage === "/projects") return SwiperData1ToMobile;
      else return SwiperDataMobile;
    } else if (currentPage === "/sderot") return SwiperData2;
    if (currentPage === "/projects") return SwiperData1;
    else return SwiperDataDefault;
  };
  const getWidth = () => {
    if (isMobile) return "90%";
    else if (isHandlesPage) return "78%";
    else if (isHDScreen) return "85%";
    else return "91%";
  };
  return (
    <div className="App">
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route
          path="/projects"
          element={<DetailsContainer item={navList[0]} />}
        />
        <Route
          path="/sderot"
          element={<DetailsContainer item={navList[1]} />}
        />
        <Route
          path="/pirzul"
          element={<DetailsContainer item={navList[2]} />}
        />
      </Routes>
      <div
        style={{
          background: !isHomePage && "#EECCA2",
          padding: isMobile ? "1px 0 1px" : "1px 0 1px",
          margin: "auto",
        }}
      >
        <div
          style={{
            width: getWidth(),
            margin: " 0 auto 0px ",
          }}
        >
          {isHandlesPage ? (
            <HandleViewer handles={isMobile ? HandlesToMobile : Handles} />
          ) : (
            <MySwiper key={Math.random()} images={getImages()} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
