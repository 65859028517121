import Img1 from "./../../assets/images/domain/Home/img11.jpeg";
import Img2 from "./../../assets/images/domain/Home/img22.jpeg";
import Img3 from "./../../assets/images/domain/Home/img33.jpg";
import H1 from "./../../assets/images/domain/Handles/img1.jpg";
import H2 from "./../../assets/images/domain/Handles/img2.jpg";
import H3 from "./../../assets/images/domain/Handles/img3.jpg";
import H4 from "./../../assets/images/domain/Handles/img4.jpg";
import H5 from "./../../assets/images/domain/Handles/img5.jpg";
import H6 from "./../../assets/images/domain/Handles/img6.jpg";
import H7 from "./../../assets/images/domain/Handles/img7.jpg";
import Img11 from "../../assets/images/domain/Home/img1.jpeg";
import Img22 from "../../assets/images/domain/Home/img2.png";
import Img33 from "../../assets/images/domain/Home/img3.jpeg";
import Img44 from "../../assets/images/domain/Home/img4.jpeg";
import Img55 from "../../assets/images/domain/Home/img55.png";
import Img10 from "../../assets/images/domain/Home/img10.jpeg";
import Img20 from "../../assets/images/domain/Home/img20.jpeg";
import Img30 from "../../assets/images/domain/Home/img22.jpeg";
/*
בעל החברה הינו מהנדס שימור ויוצא מחלקת שימור בעיריית תל אביב יפו-\n
ובעל ניסיון של 12 שנה במבנים לשימור.\n
*/
export const navList = [
  {
    id: 1,
    image: Img1,
    title: "פרויקטים",
    description: `חברתנו הינה חברת ייצור חלונות עץ למבנים לשימור,\n
המותאמים למבנה לפי תכניות אדריכליות באיכות עץ מיוחדים ועמידים ביותר בתנאי מזג האוויר בארץ.\n
צביעת העץ תקנית ותואמת ללחות הקיימת בארץ.\n
  \n
כל העבודות מבוצעות בתאום עם אדריכל הפרויקט ומחלקת שימור לאישור כל הפרטים הכי קטנים במוצר,\n
כולל אישור ה- Shop drawing וחלון הדוגמה לפני התחלת הפרויקט.\n
  \n
  \n
  \n
    `,
  },
  {
    id: 2,
    image: Img2,
    title: "שדרות ירושלים 111",
    subTitle: "יפו",
    description: `הבניין נבנה ב- 1932 כבית מגורים עבור משפחת זיפטה,\n
משפחה ערבית נוצרית ותיקה ואמידה ביפו שנאלצה לעקור ללבנון ב- 1948.\n
  \n
הבית עבר לידי המדינה על פי חוק נכסי נפקדים, שימש כמשרד צבאי ובשנים האחרונות עמד נטוש.\n
  \n
לאחרונה הושב לקהילה הערבית לטובת בית הדין השרעי, הדתי מוסלמי של יפו.\n
  \n
הפרויקט כולל חיזוק היסודות הקיימים והנגשת המבנה-\n
עבודת השימור מייצגת את מאפייני המבנה האקלקטי שבעל השפעה אוריינטלית.\n
    
    `,
  },
  {
    id: 3,
    image: Img3,
    title: "פירזול",
    description: `הפרזול היינו החלק המשלים לאותנטיות ושלמות יופיו של החלון לשימור (חלון עץ).\n
    \n
האביזרים הנבחרים על ידי האדריכלים ובעלי המקצוע מהווים חלק אינטגראלי מהשלמות מראה החלון או הדלת שעוצבה במיוחד למראה האותנטי והעתיק של המבנה.\n
  \n
יש לתת דגש ותשומת לב רבה לבחירת הפרזול בחלון או בדלת מעץ מפני שדרוג האלמנט הינו מתבטא בסוג הידית והציריה של החלון.\n
    `,
  },
];

export const Handles = [
  {
    id: 1,
    img: H1,
    name: "ידית חלון אלומיניום",
  },
  {
    id: 2,
    img: H2,
    name: "כיסוי צירים סטנדרט",
  },
  {
    id: 3,
    img: H3,
    name: "ידית חלון פליז כפולה לקרמון פנימי",
  },
  {
    id: 4,
    img: H4,
    name: "ידית חלון סטנדרטית",
  },
  {
    id: 5,
    img: H5,
    name: "ידית פליז",
  },
  {
    id: 6,
    img: H6,
    name: "ידית חלון פליז לקרמון פנימי",
  },
  {
    id: 7,
    img: H7,
    name: "דגם צירים לשימור",
  },
];

export const HandlesToMobile = [
  {
    id: 1,
    img: H1,
    name: "ידית חלון אלומיניום",
  },
  {
    id: 2,
    img: H3,
    name: "ידית חלון פליז כפולה לקרמון פנימי",
  },
  {
    id: 3,
    img: H2,
    name: "כיסוי צירים סטנדרט",
  },
  {
    id: 6,
    img: H6,
    name: "ידית חלון פליז לקרמון פנימי",
  },
  {
    id: 4,
    img: H4,
    name: "ידית חלון סטנדרטית",
  },
  {
    id: 5,
    img: H5,
    name: "ידית פליז",
  },

  {
    id: 7,
    img: H7,
    name: "דגם צירים לשימור",
  },
];

export const SwiperDataMobile = [
  {
    id: 1,
    title: "שדרות ירושלים 111",
    subTitle: "יפו",
    image: Img11,
  },
  {
    id: 2,
    title: "זלינהוף 7",
    subTitle: "יפו",
    image: Img22,
  },
  {
    id: 3,
    title: "רוטמן 18",
    subTitle: "יפו",
    image: Img33,
  },
  {
    id: 1,
    title: " ",
    subTitle: "יפו",
    image: Img44,
  },
];
export const SwiperDataDefault = [
  {
    id: 3,
    title: "רוטמן 18",
    subTitle: "יפו",
    image: Img33,
  },
  {
    id: 2,
    title: "זלינהוף 7",
    subTitle: "יפו",
    image: Img10,
  },
  {
    id: 1,
    title: "שדרות ירושלים 111",
    subTitle: "יפו",
    image: Img11,
  },

  {
    id: 2,
    title: "זלינהוף 7",
    subTitle: "יפו",
    image: Img22,
  },
  {
    id: 2,
    title: "זלינהוף 7",
    subTitle: "יפו",
    image: Img55,
  },
  {
    id: 1,
    title: " ",
    subTitle: "יפו",
    image: Img44,
  },
];

export const SwiperData1 = [
  {
    id: 1,
    title: "בקרוב",
    image: Img44,
  },
  {
    id: 2,
    title: "בקרוב",
    image: Img20,
  },
  {
    id: 1,
    title: "שדרות ירושלים 111",
    subTitle: "יפו",
    image: Img30,
    privateLink: "/sderot",
  },
  {
    id: 4,
    image: Img33,
    title: "בקרוב",
  },
  {
    id: 5,
    image: Img10,
    title: "בקרוב",
  },
  {
    id: 6,
    image: Img11,
    title: "בקרוב",
  },
  // {
  //   id: 7,
  //   image: Img22,
  //   title: "בקרוב",
  // },
  // {
  //   id: 8,
  //   image: Img55,
  //   title: "בקרוב",
  // },
];
export const SwiperData1ToMobile = [
  {
    id: 1,
    title: "שדרות ירושלים 111",
    subTitle: "יפו",
    image: Img30,
    privateLink: "/sderot",
  },
  {
    id: 2,
    title: "בקרוב",
    image: Img20,
  },
  {
    id: 1,
    title: "בקרוב",
    image: Img44,
  },
  {
    id: 4,
    image: Img33,
    title: "בקרוב",
  },
  {
    id: 5,
    image: Img10,
    title: "בקרוב",
  },
  {
    id: 6,
    image: Img11,
    title: "בקרוב",
  },
  // {
  //   id: 7,
  //   image: Img22,
  //   title: "בקרוב",
  // },
  // {
  //   id: 8,
  //   image: Img55,
  //   title: "בקרוב",
  // },
];
export const SwiperData2 = [
  {
    id: 1,
    image: Img44,
  },
  {
    id: 2,
    image: Img20,
  },
  {
    id: 3,
    image: Img30,
  },
  {
    id: 4,
    image: Img33,
  },
  {
    id: 5,
    image: Img10,
  },
  {
    id: 6,
    image: Img11,
  },
  // {
  //   id: 7,
  //   image: Img22,
  // },
  // {
  //   id: 8,
  //   image: Img55,
  // },
];
export const SwiperData2ToMobile = [
  {
    id: 3,
    image: Img30,
  },
  {
    id: 2,
    image: Img20,
  },
  {
    id: 1,
    image: Img44,
  },
  {
    id: 4,
    image: Img33,
  },
  {
    id: 5,
    image: Img10,
  },
  {
    id: 6,
    image: Img11,
  },
  // {
  //   id: 7,
  //   image: Img22,
  // },
  // {
  //   id: 8,
  //   image: Img55,
  // },
];
