import React, { useEffect } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

const Wrapper = styled.div`
  @media (max-width: 575.98px) {
    margin: 0 0 70px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    margin: 0 0 44px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    margin: 0 0 66px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    margin: 0 0 74px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    margin: 0 0 98px;
  }
`;
const ProductContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 410px;
  text-align: center;
  @media (max-width: 575.98px) {
    height: 322px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    height: 209px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    height: 320px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    height: 360px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    height: 480px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Title = styled.p`
  font-family: "Almoni";
  font-weight: 400;
  line-height: 20.391px;
  margin: 20px 0 0;
  text-align: right;
  color: #583715;
  @media (max-width: 575.98px) {
    font-size: 15px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 15px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 16px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 18px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 22px;
  }
`;
const SubTitle = styled.p`
  font-family: "Almoni";
  line-height: 20.391px;
  font-weight: 400;
  margin: 0;
  text-align: right;
  color: rgba(88, 55, 21, 0.6);
  @media (max-width: 575.98px) {
    font-size: 14px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 14px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 14px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 14px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 18px;
  }
`;
const Header = styled.h2`
  font-family: "Almoni";
  color: #583715;
  margin: ${({ isMobile }) =>
    isMobile ? "10px 10px 30px 0" : "10px 50px 60px 0"};
  margin: ${({ isMobile }) => (isMobile ? "30px 0" : "40px 5%")};
  text-align: right;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.188px;
  direction: rtl;
  @media (max-width: 575.98px) {
    font-size: 15px;
    line-height: 12px;
    margin-right: 10px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 15px;
    line-height: 12px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 16px;
    line-height: 18.125px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 16px;
    line-height: 20.391px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 22px;
    line-height: 27.188px;
  }
`;
const SlideLink = styled(Link)``;
const MySwiper = ({ images }) => {
  const isMobile = window.matchMedia("(max-width: 600px)").matches;
  const isHomePage = useLocation().pathname === "/";
  const currentPage = useLocation().pathname;
  const getSlideWidth = (index) => {
    // Determine the width of each slide based on its position relative to the active slide
    if (index % 3 === 1) {
      return "45%"; // Width of the center slide
    } else {
      return "20%"; // Width of other slides
    }
  };
  const getCureentHeader = () => {
    if (currentPage === "/projects") return " בחרו פרויקט: ";
    if (currentPage === "/sderot") return " תמונות נוספות: ";
    if (currentPage === "/pirzul") return "תמונות נוספות:";
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);
  return (
    <Wrapper>
      <Header isMobile={isMobile}>{getCureentHeader()}</Header>
      <Swiper
        style={{
          padding: isMobile ? "0 56px" : " 0 4%",
          boxSizing: "border-box",
        }}
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={isMobile ? 60 : "7%"}
        slidesPerView={isMobile ? 1 : "auto"}
        navigation
        loop={true}
        slidesPerGroup={isMobile ? 1 : 3}
        speed={300}
      >
        {images.map((i, index) => (
          <SwiperSlide
            key={index}
            style={{ width: isMobile ? "100%" : getSlideWidth(index) }}
            className="swiper-slide"
          >
            {i.privateLink ? (
              <SlideLink to={i.privateLink}>
                <ProductContainer>
                  <Image src={i.image} />
                </ProductContainer>
              </SlideLink>
            ) : (
              <ProductContainer>
                <Image src={i.image} />
              </ProductContainer>
            )}
            {!isHomePage && (
              <>
                <Title>{i.title}</Title>
                <SubTitle>{i.subTitle}</SubTitle>
              </>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};

export default MySwiper;
