import React, { useState } from "react";
import styled from "styled-components";
import mainLogo from "./../../../../assets/images/domain/Common/Layout/Navbar/Logo1.png";
import Menu from "./../../../../assets/images/domain/Common/Layout/Navbar/Menu.png";
import closeIcon from "./../../../../assets/images/domain/Common/Layout/Navbar/close.png";
import { Link, NavLink } from "react-router-dom";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? "90vh" : "auto")};
  background-color: ${({ isOpen }) =>
    isOpen ? "rgba(88, 55, 21, 0.98)" : "none"};
  opacity: ${({ isOpen }) => (isOpen ? "0.98" : "1")};
  z-index: 2;
`;
const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  @media (max-width: 575.98px) {
    padding: 25px;
    align-items: center;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    padding: 30px 70px 22px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    padding: 48px 108px 23px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    padding: 63px 121px 29px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    padding: 72px 162px 0px;
  }
`;
const Logo = styled.img`
  @media (max-width: 575.98px) {
    width: 99px;
    height: 70px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    width: 70px;
    height: 50px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    width: 109px;
    height: 76px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    width: 122px;
    height: 86px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    width: 163px;
    height: 114px;
  }
`;
const NavbarIcon = styled.img`
  @media (max-width: 575.98px) {
    width: ${({ isOpen }) => (isOpen ? "18px" : "20px")};
    height: ${({ isOpen }) => (isOpen ? "18px" : "9px")};
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    width: ${({ isOpen }) => (isOpen ? "14px" : "18px")};
    height: ${({ isOpen }) => (isOpen ? "14px" : "9px")};
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    width: ${({ isOpen }) => (isOpen ? "20px" : "27px")};
    height: ${({ isOpen }) => (isOpen ? "20px" : "12px")};
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    width: ${({ isOpen }) => (isOpen ? "23px" : "31px")};
    height: ${({ isOpen }) => (isOpen ? "23px" : "13px")};
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    width: ${({ isOpen }) => (isOpen ? "30px" : "41px")};
    height: ${({ isOpen }) => (isOpen ? "30px" : "17px")};
  }
`;
const NavList = styled.ul`
  padding: 40px 0;
  width: 60%;
  text-align: center;
  color: white;
  list-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
`;
const Item = styled(NavLink)`
  font-family: "Almoni";
  font-weight: 400;
  line-height: 52.5px;
  display: block;
  text-decoration: none;
  color: white;
  padding: 10px;
  &:hover {
    color: #eecca2;
  }

  &.active {
    color: #eecca2;
  }
  @media (max-width: 575.98px) {
    font-size: 23px;
    &.external {
      padding: 0;
      line-height: 40px;
    }
    &.internal {
      font-size: 16px;
      line-height: 27px;
      padding: 0;
    }
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 20px;
    &.external {
      padding: 0;
      line-height: 33px;
    }
    &.internal {
      font-size: 14px;
      line-height: 22px;
      padding: 0;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 22px;
    &.external {
      padding: 0;
      line-height: 50px;
    }
    &.internal {
      font-size: 14px;
      line-height: 30px;
      padding: 0;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 25px;
    &.external {
      padding: 0;
      line-height: 50px;
    }
    &.internal {
      font-size: 16px;
      line-height: 30px;
      padding: 0;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 32px;
    &.external {
      padding: 0;
      line-height: 60px;
    }
    &.internal {
      font-size: 22px;
      line-height: 30px;
      padding: 0;
    }
  }
`;
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Wrapper isOpen={isOpen}>
      <LogoContainer>
        <Link to="/">
          <Logo src={mainLogo} />
        </Link>
        <NavbarIcon
          src={isOpen ? closeIcon : Menu}
          isOpen={isOpen}
          onClick={handleClick}
        ></NavbarIcon>
      </LogoContainer>
      {isOpen && (
        <NavList>
          <Item to={"/"} onClick={handleClick}>
            עמוד הבית
          </Item>
          <Item to={"/projects"} onClick={handleClick} className={"external"}>
            פרויקטים
          </Item>
          <Item to={"/sderot"} onClick={handleClick} className={"internal"}>
            שדרות ירושלים 111, יפו
          </Item>
          <Item to={"/pirzul"} onClick={handleClick}>
            פירזול
          </Item>
        </NavList>
      )}
    </Wrapper>
  );
};

export default Navbar;
