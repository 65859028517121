import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: ${({ isMobile }) => (isMobile ? "90%" : "80%")};
  width: ${({ isMobile }) => (isMobile ? "84%" : "83%")};
  /* width: ${({ isMobile }) => (isMobile ? "90%" : "77%")}; */
  margin: 0px auto 80px;
  @media (max-width: 575.98px) {
    margin: 0px auto 60px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    margin: 0px auto 50px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    margin: 0px auto 70px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 28px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 32px;
    width: 77%;
  }
`;
const Container = styled.div`
  display: ${({ isMobile }) => (isMobile ? "block" : "flex")};
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;
const TextsContainer = styled.div`
  color: #583715;
  text-align: right;
  padding: 0;
  width: ${({ isMobile }) => (isMobile ? "100%" : "60%")};
  @media (max-width: 575.98px) {
    margin: 0px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    margin: 0px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    margin: 40px 0 0 0;
    margin: ${({ hasSubTitle }) => (hasSubTitle ? "0" : "40px 0 0 0")};
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 28px;
    margin: ${({ hasSubTitle }) => (hasSubTitle ? "0" : "44px 0 0 0")};
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 32px;
  }
`;
const Title = styled.h2`
  text-align: right;
  font-family: "Almoni";
  font-weight: 500;
  line-height: 27.188px;
  margin: 0 0 52px;
  color: #583715;
  @media (max-width: 575.98px) {
    font-size: 22px;
    margin: ${({ subTitle }) => (subTitle ? "0 0 10px" : "0 0 17px")};
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 22px;
    margin: ${({ subTitle }) => (subTitle ? "0 0 10px" : "0px 0 39px")};
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 24px;
    margin: ${({ subTitle }) => (subTitle ? "0 0 10px" : "0px 0 20px")};
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 28px;
    margin: ${({ subTitle }) => (subTitle ? "0 0 10px" : "0px 0 20px")};
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 32px;
    margin: ${({ subTitle }) => (subTitle ? "0 0 10px" : "0 0 54px")};
  }
`;
const SubTitle = styled.p`
  color: rgba(88, 55, 21, 0.6);
  font-family: "Almoni";
  font-weight: 400;
  line-height: 27.188px;
  font-size: 16px;
  text-align: right;
  margin: 0px 0 10px;
  @media (max-width: 575.98px) {
    margin: 0px 0 20px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    margin: 0px 0 15px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    margin: 0px 0 17px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    margin: 0px 0 22px;
  }
  @media (min-width: 1441px) {
    font-size: 18px;
    margin: 0px 0 22px;
  }
`;
const Description = styled.p`
  margin: 0;
  direction: rtl;
  white-space: pre-wrap;
  font-family: "Almoni";
  font-weight: 400;
  line-height: 25px;
  @media (max-width: 575.98px) {
    font-size: 15px;
    line-height: 22px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 15px;
    line-height: 22px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 16px;
    line-height: 22px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 18px;
    line-height: 25px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 22px;
    line-height: 32px;
  }
`;
const ImageContainer = styled.div`
  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    width: 324px;
    height: 485px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    width: 364px;
    height: 545px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    width: 387px;
    height: 578px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const DetailsContainer = ({ item }) => {
  const isMobile = window.matchMedia("(max-width: 834px)").matches;
  const lines = item.description.split("\n");
  return (
    <Wrapper isMobile={isMobile}>
      <Title subTitle={item.subTitle}>{item.title}</Title>
      <Container isMobile={isMobile}>
        {!isMobile && (
          <ImageContainer>
            <Image src={item.image} />
          </ImageContainer>
        )}
        <TextsContainer isMobile={isMobile} hasSubTitle={item.subTitle}>
          {item.subTitle && <SubTitle>{item.subTitle}</SubTitle>}
          {lines.map((line, index) => (
            <Description>{line}</Description>
          ))}
        </TextsContainer>
      </Container>
    </Wrapper>
  );
};

export default DetailsContainer;
