import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 0 80px 0;
`;
const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
`;
const Container = styled.div`
  width: 22%;
  &.bigslide {
    width: 48%;
  }
  @media (max-width: 575.98px) {
    height: 260px;
    width: 42%;
    margin: auto;
    gap: 20px;
    &.bigslide {
      width: 98%;
    }
  }
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 410px;
  @media (max-width: 575.98px) {
    height: 210px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    height: 210px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    height: 324px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    height: 364px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    height: 485px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Name = styled.p`
  font-family: "Almoni";
  font-size: 22px;
  font-weight: 500;
  line-height: 27.188px;
  color: #583715;
  text-align: right;
  @media (max-width: 575.98px) {
    font-size: 15px;
    line-height: 17px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 15px;
    line-height: 17px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 16px;
    line-height: 18px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 18px;
    line-height: 20px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 22px;
    line-height: 27.188px;
  }
`;
const Header = styled.h2`
  font-family: "Almoni";
  color: #583715;
  margin: ${({ isMobile }) =>
    isMobile ? "10px 10px 30px 0" : "10px 50px 60px 0"};
  margin: ${({ isMobile }) => (isMobile ? "30px 0" : "40px 0")};
  text-align: right;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.188px;
  direction: rtl;
  @media (max-width: 575.98px) {
    font-size: 15px;
    line-height: 12px;
    margin-right: 10px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 15px;
    line-height: 12px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 16px;
    line-height: 18.125px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 18px;
    line-height: 20.391px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 22px;
    line-height: 27.188px;
  }
`;
const HandleViewer = ({ handles }) => {
  const isMobile = window.matchMedia("(max-width: 600px)").matches;
  const getClass = (index) => {
    if (isMobile && (index === 2 || index === 3 || index === 6))
      return "bigslide";
    else if (!isMobile && (index === 1 || index === 5 || index === 6))
      return "bigslide";
    else return "default";
  };
  return (
    <Wrapper>
      <Header isMobile={isMobile}>תמונות נוספות:</Header>
      <CardsWrapper isMobile={isMobile}>
        {handles.map((h, index) => (
          <Container className={getClass(index)} isMobile={isMobile}>
            <ImageWrapper>
              <Image src={h.img} />
            </ImageWrapper>

            <Name>{h.name}</Name>
          </Container>
        ))}
      </CardsWrapper>
    </Wrapper>
  );
};

export default HandleViewer;
