import React from "react";
import styled from "styled-components";
import WindowLogo from "./../../../../assets/images/domain/Common/Layout/Footer/Logo white.png";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #583715;
  color: white;
  /* @media (min-width: 768px) {
    padding: 40px 0 20px;
  } */
  @media (max-width: 575.98px) {
    padding: 35px 0 24px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    padding: 27px 0 10px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    padding: 29px 0 12px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    padding: 33px 0 16px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    padding: 45px 0 16px;
  }
`;
const LinkEl = styled(Link)`
  color: white;
  text-decoration: none;
`;
const Logo = styled.img`
  @media (max-width: 575.98px) {
    width: 99px;
    height: 70px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    width: 71px;
    height: 50px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    width: 80px;
    height: 56px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    width: 90px;
    height: 64px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    width: 104px;
    height: 73px;
  }
`;
const ConnectionContainer = styled.div`
  text-align: center;
  font-family: "Almoni";
  @media (max-width: 575.98px) {
    font-weight: 500;
    line-height: 19px;
    font-size: 15px;
    margin: 30px 0 48px 0;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-weight: 500;
    line-height: 19px;
    font-size: 15px;
    margin: 22px 0 30px 0;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-weight: 500;
    line-height: 19px;
    font-size: 14px;
    margin: 24px 0 35px 0;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    margin: 27px 0 43px 0;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-weight: 500;
    line-height: 27px;
    font-size: 18px;
    margin: 38px 0 45px 0;
  }
`;
const CopyrightContainer = styled.div`
  text-align: center;
  font-family: "Almoni";
  @media (max-width: 575.98px) {
    font-weight: 500;
    line-height: 13px;
    font-size: 12px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-weight: 500;
    line-height: 12px;
    font-size: 12px;
    display: flex;
    gap: 5px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-weight: 500;
    line-height: 18px;
    font-size: 12px;
    display: flex;
    gap: 5px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-weight: 500;
    line-height: 20.188px;
    font-size: 12px;
    display: flex;
    gap: 5px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-weight: 500;
    line-height: 27.188px;
    font-size: 14px;
    display: flex;
    gap: 5px;
  }
`;
const ParagraphEl = styled.p`
  margin: 0;
`;
const Footer = () => {
  return (
    <Wrapper>
      <Logo src={WindowLogo} />
      <ConnectionContainer>
        <ParagraphEl>רחוב יאנוש קורצ׳אק 19 ת״א-יפו, 6805023 </ParagraphEl>
        <ParagraphEl>0544801987 | 0552505050 </ParagraphEl>
        <ParagraphEl>office@shababeek.co</ParagraphEl>
      </ConnectionContainer>
      <CopyrightContainer>
        <LinkEl to={"https://www.abrandingraphics.com"}>
          <ParagraphEl>Design: A brandingraphics. </ParagraphEl>
        </LinkEl>
        <ParagraphEl> Develop: A dev. </ParagraphEl>
        <ParagraphEl>2024 All rights reserved. </ParagraphEl>
      </CopyrightContainer>
    </Wrapper>
  );
};

export default Footer;
