import React from "react";
import styled from "styled-components";
import mainBackground from "./../../../../assets/images/domain/Common/Layout/Header/mainBackground.png";
import mainBackgroundToMobail from "./../../../../assets/images/domain/Common/Layout/Header/mainBackground1.png";
import Window from "./../../../../assets/images/domain/Common/Layout/Header/windows.gif";
import Navbar from "../Navbar/Navbar";
import { useLocation } from "react-router-dom";
const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${({ isHomePage }) => (isHomePage ? "90vh" : "auto")};
  background-image: url(${({ mainbackground, isHomePage }) =>
    isHomePage ? mainbackground : "none"});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
`;
const DescriptionContainer = styled.div`
  text-align: center;
  position: absolute;
  top: 55%;
  left: 0%;
  transform: translate(-0%, -45%);
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
`;
const WindowImage = styled.img`
  @media (max-width: 575.98px) {
    width: 183px;
    height: 137px;
    margin-bottom: 40px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    width: 126px;
    height: 95px;
    margin-bottom: 32px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    width: 194px;
    height: 145px;
    margin-bottom: 52px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    width: 218px;
    height: 164px;
    margin-bottom: 55px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    width: 290px;
    height: 218px;
    margin-bottom: 99px;
  }
`;
const Title = styled.h2`
  text-align: center;
  color: #583715;
  font-family: "Arbel";
  font-weight: 500;
  line-height: 65px;
  margin: 0;
  @media (max-width: 575.98px) {
    font-size: 38px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 30px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 45px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 50px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 65px;
  }
`;
const Header = () => {
  const isMobile = window.matchMedia("(max-width: 600px)").matches;
  const isHomePage = useLocation().pathname === "/";
  return (
    <Wrapper
      mainbackground={isMobile ? mainBackgroundToMobail : mainBackground}
      isHomePage={isHomePage}
    >
      <Navbar />
      {isHomePage && (
        <DescriptionContainer>
          <WindowImage src={Window} />
          <Title>מדמיון למציאות</Title>
        </DescriptionContainer>
      )}
    </Wrapper>
  );
};

export default Header;
