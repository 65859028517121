import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const About = styled.div`
  text-align: center;
`;
const Title = styled.h2`
  color: #583715;
  margin: 37px 0 24px;
  font-family: "Arbel";
  font-weight: 500;
  line-height: 32px;
  font-style: normal;
  @media (max-width: 575.98px) {
    font-size: 23px;
    line-height: 65px;
    margin: 37px 0 21px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 20px;
    margin: 37px 0 37px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 22px;
    margin: 55px 0 43px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 25px;
    margin: 66px 0 49px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 32px;
    margin: 78px 0 61px;
  }
`;
const DescriptionContainer = styled.div`
  direction: rtl;
  text-align: center;
  width: 100%;
  margin: auto;
  color: #583715;
  font-family: "Almoni";
  font-weight: 400;

  @media (max-width: 575.98px) {
    font-size: 15px;
    line-height: 19px;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 834px) {
    font-size: 14px;
    line-height: 18px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 835px) and (max-width: 1280px) {
    font-size: 16px;
    line-height: 22px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 1281px) and (max-width: 1440px) {
    font-size: 16.5px;
    line-height: 24px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1441px) {
    font-size: 22px;
    line-height: 32px;
  }
`;
const Main = () => {
  const isMobile = window.matchMedia("(max-width: 600px)").matches;
  const isHD = window.matchMedia("(min-width: 1441px)").matches;

  return (
    <Wrapper>
      <About>
        <Title>עלינו</Title>
        <div>
          {!isMobile && (
            <>
              <DescriptionContainer>
                חברתנו עוסקת בכל סוגי הנגרות, לרבות חלונות ודלתות.
              </DescriptionContainer>
              <DescriptionContainer>
                אנו מתמחים במתן כל עבודות הברזל והאלומיניום ומתן כל שירותי
                ההנדסה ושרטוטי היישום.
              </DescriptionContainer>
              <DescriptionContainer>
                בעל החברה הינו מהנדס שימור ויוצא מחלקת שימור בעיריית תל אביב
                יפו-
              </DescriptionContainer>
              <DescriptionContainer>
                ובעל ניסיון של 12 שנה במבנים לשימור.
              </DescriptionContainer>
              <br />
              {isHD && <br />}
            </>
          )}

          {isMobile && (
            <>
              <DescriptionContainer>
                חברתנו עוסקת בכל סוגי הנגרות,
              </DescriptionContainer>
              <DescriptionContainer>לרבות חלונות ודלתות.</DescriptionContainer>
              <DescriptionContainer>
                אנו מתמחים במתן כל עבודות
              </DescriptionContainer>
              <DescriptionContainer>הברזל והאלומיניום</DescriptionContainer>
              <DescriptionContainer>
                ומתן כל שירותי ההנדסה ושרטוטי היישום.
              </DescriptionContainer>
              <DescriptionContainer>
                בעל החברה הינו מהנדס שימור ויוצא מחלקת
              </DescriptionContainer>
              <DescriptionContainer>
                שימור בעיריית תל אביב יפו-
              </DescriptionContainer>
              <DescriptionContainer>
                ובעל ניסיון של 12 שנה במבנים לשימור.
              </DescriptionContainer>
              <br />
            </>
          )}
        </div>
      </About>
    </Wrapper>
  );
};

export default Main;
